<template>
  <div class="main-wrapper">
    <!---Navigation-->
    <Navbar
      :topbarColor="topbarColor"
      :logo="require('@/assets/images/logo/logo-light-icon.png')"
      :title="logotitle"
    />
    <!---Sidebar-->
    <SideBar parent=".main-wrapper" :sidebarLinks="sidebarLinks" />
    <!---Page Container-->
    <div class="main-container-fluid">
      <transition appear name="fade">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
      <footer class="contentFooter">
        <p>&copy;{{ year }} Utupay. All rights reserved</p>
        <a href="https://utupay.co/merchant/agreemeent" target="_blank"
          >Merchant Agreement</a
        >
        <div class="dot"></div>
        <a href="https://utupay.co/terms" target="_blank">Terms of Use</a>
        <div class="dot"></div>
        <a href="https://utupay.co/privacy-policy" target="_blank"
          >Privacy Policy</a
        >
      </footer>
    </div>
  </div>
</template>

<script>
import Navbar from "@/layout/main/header/Navbar.vue";
import SideBar from "@/layout/main/sidebar/SideBar.vue";
import sidebarLinks from "@/layout/main/sidebar/sidebarlinks.js";

export default {
  name: "MainContainer",
  components: {
    Navbar,
    SideBar,
  },
  data: () => ({
    topbarColor: "#492540",
    logotitle: "UTUPAY DASHBOARD",
    sidebarLinks: sidebarLinks,
  }),
  computed: {
	year() {
		return new Date().getFullYear();
	}
  },
  methods: {},
};
</script>