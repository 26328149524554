<template>
	<header class="gridx">
		<vs-navbar
      v-model="indexActive"
      :color="topbarColor"
      class="topnavbar" text-color="rgba(255,255,255,0.7)" active-text-color="rgba(255,255,255,1)">
      <!---
      Template logo
      --> 
      <div slot="title" class="themelogo d-flex justify-content-center">
        <img :src="logo" v-if="logo" alt="Dashboard"/>
        <span class="logo-text pl-2" v-if="title">{{ title }}</span>
      </div>
      <!---
      Mobile toggle
      --> 
      <div slot="title">
        <div class="hiddenDesktop cursor-pointer" @click.stop="activeSidebar"><vs-icon icon="menu"></vs-icon></div>
      </div>
      <!---
      Craete new dd
      -->   
      <!-- <vs-button color="danger" href="https://www.wrappixel.com" type="filled">Upgrade to Pro</vs-button> -->

     

      <vs-spacer></vs-spacer>

      <vs-dropdown vs-trigger-click left class="cursor-pointer pr-2 pl-2 ml-1 mr-1 responsive-margin">
         <a class="text-white-dark" href="#">
           <div class="env d-flex align-items-center">
             <span class="font-weight-bold">
               <vs-icon :icon="isTest ? 'info' : 'check_circle'" :color="isTest ? 'warning' : 'success'"></vs-icon>
               {{selectedOption === 'test' ? 'Test Mode' : 'Live Mode'}}
               <vs-icon icon="arrow_drop_down"></vs-icon>
            </span>
           </div>
         </a>
        <vs-dropdown-menu class="topbar-dd">
          <vs-dropdown-item @click="selection('test')">
            <div class="pt-1 pb-1">
              <span class="font-weight-normal">
                <vs-icon class="mr-2" size="18px" icon="info" color="warning"></vs-icon>
                Test Mode
              </span>
            </div>
          </vs-dropdown-item>
          <vs-divider class="m-0 p-0"></vs-divider>
          <vs-dropdown-item @click="selection('live')">
            <div class="pt-1 pb-1">
              <span class="font-weight-normal">
                <vs-icon class="mr-2" size="18px" icon="check_circle" color="success"></vs-icon>
                Live Mode
              </span>
            </div>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>

      <!-- Documentation Button -->
      <vs-button type="flat" text-color="white" href="https://utupay.africa/docs?utm-source=dashboard-navbar" target>Documentation</vs-button>
       
      <!---
      Craete new dd
      -->   
      <vs-dropdown  vs-trigger-click left class="cursor-pointer pr-2 pl-2 ml-1 mr-1">
         <a class="text-white-dark" href="#"><vs-icon icon="notifications"></vs-icon></a>
        <vs-dropdown-menu class="topbar-dd">
          <vs-dropdown-item>Action</vs-dropdown-item>
          <vs-dropdown-item>Another Action</vs-dropdown-item>
          <vs-dropdown-item>Something</vs-dropdown-item>
          <vs-dropdown-item>Here</vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <!---
      Craete new dd
      -->   
      <!-- <vs-dropdown  vs-trigger-click left class="cursor-pointer pr-2 pl-2 ml-1 mr-1">
         <a class="text-white-dark" href="#"><vs-icon icon="mode_comment"></vs-icon></a>
        <vs-dropdown-menu class="topbar-dd">
          <vs-dropdown-item>Action</vs-dropdown-item>
          <vs-dropdown-item>Another Action</vs-dropdown-item>
          <vs-dropdown-item>Something</vs-dropdown-item>
          <vs-dropdown-item>Here</vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown> -->
      <!---
      Craete new dd
      -->   
      <vs-dropdown  vs-trigger-click left class="cursor-pointer pr-2 pl-2 ml-1 mr-md-3">
         <a class="text-white-dark user-image" href="#"><vs-icon class="mr-2" bg="grey" size="30px" color="white" icon="account_circle" round></vs-icon></a>
        <vs-dropdown-menu class="move-up">          
            <!-- <vs-dropdown-item><vs-icon icon="person_outline" class="mr-1"></vs-icon> My Profile</vs-dropdown-item>
            <vs-dropdown-item><vs-icon icon="sentiment_very_satisfied" class="mr-1"></vs-icon> My Balance</vs-dropdown-item>
            <vs-dropdown-item><vs-icon icon="mail_outline" class="mr-1"></vs-icon> Inbox</vs-dropdown-item>
            <hr class="mb-1" />
            <vs-dropdown-item><vs-icon icon="gps_not_fixed" class="mr-1"></vs-icon> Account Setting</vs-dropdown-item>
            <vs-dropdown-item><vs-button type="filled" class="mr-1 w-100" @click="logout">Logout</vs-button></vs-dropdown-item> -->
            <div>
              <div class="d-flex user-tip align-items-center p-2">
                <img class="img-bg" src="@/assets/images/logo/logo-light-icon.png" width="50px" height="50px" alt="">
                <div class="text-muted ml-2">
                  <h3 class="mb-2">{{user.user_name}}</h3>
                  <div class="d-flex">
                    <span>910skmskss</span>
                    <span class="ml-4 copy-btn bg-light text-muted" @click="copyId()">Copy Merchant Id</span>
                  </div>
                </div>
              </div>
              <div class="bg-light p-2 pb-0 text-muted mt-2">
                <p>Logged in as:</p>
                <span style="font-size:16px" class="font-weight-bold"><vs-icon class="mr-2" bg="grey" size="16px" color="white" icon="account_circle" round></vs-icon>{{user.user_email}}</span>
                <vs-button class="w-100 mt-3" @click="logout">Log out</vs-button>
              </div>
            </div>
        </vs-dropdown-menu>
      </vs-dropdown>
      
    </vs-navbar>
     
	</header>

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name : 'Navbar',
  props: {
      topbarColor: {
          type: String,
          default: "#F26D5B", //492540
      },
      title: {
            type: String,
      },
      logo: {
          type: String
      },
  },
  created() {
    this.selectedOption = this.user.environment ? this.user.environment : '';
  },
  data:()=>({
    indexActive: 0,
    showToggle: false,
    selectedOption: ''
  }),
  computed: {
    ...mapState(['user']),
    isTest() {
      return this.selectedOption === 'test';
    }
  },
  methods: {
    ...mapActions(['updateEnvironment']),
      //This is for sidebar trigger in mobile
      activeSidebar() {
          this.$store.commit('IS_SIDEBAR_ACTIVE', true);
      },
      logout() {
        this.$store.dispatch('auth/logout');
      },
      selection(val) {
        if (val === 'test') {
          this.selectedOption = 'test';
          this.updateEnvironment(val);
        } else if (val === 'live' && this.user.account_activated === true) {
          this.selectedOption = 'live';
          this.updateEnvironment(val);
        } else {
          this.$router.push('/app/activation').catch(() => {});
        }
      },
      copyId() {
        window.console.log('Copy Id!');
        let item = document.createElement('textarea');
        item.value = 'Merchant ID';
        document.body.appendChild(item);
        item.select();
        document.execCommand('copy');
        document.body.removeChild(item);
        this.$vs.notify({
          title:'Merchant ID',
          text:'Copied successfully.',
          color:'primary',position:'top-right'
        });
      },
  }
}
</script>

<style lang="scss" scoped>
  .img-bg {
    border: 1px solid #eff2f7
  }
  .env {
    height: 38px;
    border: 1px solid #eff2f7;
    border-radius: 19px;
    padding: 0px 5px;
  }

  .copy-btn {
    border: 1px solid #eff2f7;
    height: 25px;
    border-radius: 5px;
    padding: 3px 5px;
    color: black;
    font-size: 12px;
    cursor: pointer;
  }
  .move-up {
    z-index: 1500 !important;
  }
</style>