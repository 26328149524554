import displayPref from '../../../acl/acl';

export default [
	{
		url: "/app",
		name: "DASHBOARD",
		icon: "dashboard",
	},
	{
		url: "/app/transactions",
		name: "Transactions",
		icon: "receipt",
	},
	{
		url: "/app/customers",
		name: "Customers",
		icon: "supervised_user_circle",
	},
	{
		url: "/app/balance",
		name: "Balance",
		icon: "account_balance_wallet",
	},
	{
		url: "/app/settlements",
		name: "Settlements",
		icon: "account_balance",
	},
	{
		url: "/app/payment-links",
		name: "Payment Links",
		icon: "link",
	},
	{
		url: "/app/qr-code",
		name: "QR Code",
		icon: "qr_code",
	},
	{
		url: "/app/account",
		name: "Account",
		icon: "account_circle",
	},
	{
		url: "/app/settings",
		name: "Settings",
		icon: "settings",
	}
]