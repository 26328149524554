<template lang="html">

   <div id="parentx">
    <vs-sidebar default-index="1" :parent="parent" :hiddenBackground="doNotClose" color="primary" class="sidebarx" spacer v-model="isSidebarActive" :click-not-close="doNotClose" >
        <div class="header-sidebar" slot="header">
            <div class="fixed-height d-flex justify-content-center align-items-center">
              <span class="font-weight-bold">
                <img class="flex-column" src="@/assets/images/logo/logo-text.png" width="150" height="30"/>
              </span>
            </div>
        </div>
        <div v-if="!user.account_activated" class="p-3 bg-primary text-white cursor-pointer" @click="openActivation">
          <vs-row>
            <vs-col class="p-0" type="flex" vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="10">
              <h6>Activate your account</h6>
              <p class="mt-3 m-0">Complete business setup</p>
            </vs-col>
            <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="2" vs-xs="2">
              >
            </vs-col>
          </vs-row>
        </div>
        <template v-for="(sidebarLink, index) in sidebarLinks" >
            <vs-sidebar-item :icon="sidebarLink.icon" :to="sidebarLink.url" :key="`sidebarLink-${index}`" :index="index">
              <span class="hide-in-minisidebar">{{ sidebarLink.name }}</span>
            </vs-sidebar-item>
        </template>  
        
        <!-- <div class="footer-sidebar" slot="footer">
            <vs-button icon="reply" color="danger" type="flat" href="https://www.wrappixel.com">Upgrade to Pro</vs-button>
        </div> -->

    </vs-sidebar>
  </div>


</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SideBar",
  props: {
    parent: {
      type: String,
    },
    sidebarLinks: {
      type: Array,
      required: true,
    },
    index: {
      default: null,
      type: [String, Number],
    },
  },
  data: () => ({
    doNotClose: false,
    windowWidth: window.innerWidth,
  }),
  computed: {
    //This is for mobile trigger
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive;
      },
      set(val) {
        this.$store.commit("IS_SIDEBAR_ACTIVE", val);
      },
    },
    ...mapState(["user"]),
    business_name() {
      return this.$store.state.user ? this.$store.state.user.business_name : "";
    },
    user_id() {
      return this.$store.state.user ? this.$store.state.user.user_id : "";
    },
  },
  watch: {},
  methods: {
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebar();
    },
    setSidebar() {
      if (this.windowWidth < 1170) {
        this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        this.doNotClose = false;
      } else {
        this.$store.commit("IS_SIDEBAR_ACTIVE", true);
        this.doNotClose = true;
      }
    },
    openActivation() {
      this.$router.push('/app/activation');
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebar();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
    this.setSidebar();
  },
};
</script>

<style lang="scss" scoped>
.header {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* On mouse-over, add a deeper shadow */
.header:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

  .fixed-height {
    height: 40px;
  }
</style>